<template>
  <b-tabs
    pills
    vertical
  >
    <b-tab
      :title="$t('Thông tin tài khoản')"
      active
    >
      <b-card :title="$t('Thông tin tài khoản')">
        <b-row>
          <b-col md="4">
            <validation-observer
              ref="registerForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-register-form mt-2"
                @submit.prevent="register"
              >
                <b-form-group
                  :label="$t('Email')"
                  label-for="register-email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="email"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="user.email"
                      name="register-email"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  :label="$t('Merchant ID:')"
                  label-for="merchant-id"
                >
                  <b-form-input
                    id="merchant-id"
                    v-model="user.merchantCode"
                    name="merchant-id"
                    disabled
                  />
                </b-form-group>
                <b-form-group
                  :label="$t('Tên đăng nhập:')"
                  label-for="username"
                >
                  <b-form-input
                    id="username"
                    v-model="user.username"
                    name="username"
                    disabled
                  />
                </b-form-group>
              </b-form>
              <b-form-group
                :label="$t('Tên merchant:')"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Tên merchant')"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="user.name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="URL Callback:"
                label-for="callback-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="URL Callback: "
                  vid="callback-url"
                  rules="required|url"
                >
                  <b-form-input
                    id="callback-url"
                    v-model="user.payinCallbackLink"
                    name="callback-url"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                :label="$t('Ngày tạo mới:')"
                label-for="created-at"
              >
                <b-form-input
                  id="created-at"
                  v-model="dateCreated"
                  name="created-at"
                  disabled
                />
              </b-form-group>
              <b-form-group
                label="API Token:"
                label-for="api-token"
              >
                <b-form-input
                  id="api-token"
                  v-model="user.merchantKey"
                  name="api-token"
                  disabled
                />
              </b-form-group>
              <b-card-text class="d-flex">
                <b-button
                  variant="outline-danger"
                  block
                  class="mr-1"
                  style="margin-top: 0.5rem;"
                >
                  {{ $t('Huỷ') }}
                </b-button>
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="invalid"
                  class="ml-1"
                  @click="updateProfile"
                >
                  {{ $t('Lưu') }}
                </b-button>
              </b-card-text>
            </validation-observer>
          </b-col>
          <b-col md="8">
            <b-card-text>
              {{ $t('Payin - Thẻ cào') }}
            </b-card-text>
            <b-row
              cols="4"
              align-v="end"
            >
              <b-col>
                <b-form-group
                  :label="$t('Mức phí Viettel')"
                  label-for="fee-viettel"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-viettel"
                      v-model="feeViettelCard"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('Mức phí Mobifone')"
                  label-for="fee-mobifone"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-mobifone"
                      v-model="feeMobiCard"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('Mức phí Vinaphone')"
                  label-for="fee-vinaphone"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-vinaphone"
                      v-model="feeVinaCard"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('Mức phí Vietnamobile')"
                  label-for="fee-vietnamobile"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-vietnamobile"
                      v-model="feeVietnamobileCard"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-card-text>
              {{ $t('Payin - QR Bank') }}
            </b-card-text>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('Qr Bank')"
                  label-for="fee-general"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-general"
                      v-model="feeQR"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('Momo QR')"
                  label-for="fee-momo"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-momo"
                      v-model="feeMomoQr"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('Zalo QR')"
                  label-for="fee-zalo"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-zalo"
                      v-model="feeZaloPayQr"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('Viettel QR')"
                  label-for="fee-momo"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-zalo"
                      v-model="feeViettelQr"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('USDT')"
                  label-for="fee-usdt"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      id="fee-usdt"
                      v-model="feeUsdt"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-card-text class="text-bold">
              {{ $t('Phí Payout') }}
            </b-card-text>
            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$t('Payout')"
                >
                  <b-input-group
                    append="%"
                  >
                    <b-form-input
                      v-model="feePayout"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  :label="$t('usdt')"
                >
                  <b-input-group
                    append="USDT"
                  >
                    <b-form-input
                      v-model="feePayoutUsdt"
                      disabled
                    />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-card>
    </b-tab>

    <b-tab :title="$t('Đổi mật khẩu')">
      <b-card
        class="mt-3"
        :title="$t('Thay đổi mật khẩu đăng nhập')"
      >
        <validation-observer
          ref="registerForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="changePassword"
          >
            <b-row
              cols="1"
              class="w-50"
            >
              <b-col>
                <!-- password -->
                <b-form-group
                  label-for="register-password-old"
                  :label="$t('Mật khẩu cũ:')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Mật khẩu cũ:')"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password-old"
                        v-model="passwordOldValue"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password-old"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <!-- password -->
                <b-form-group
                  label-for="register-password"
                  :label="$t('Mật khẩu mới:')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Mật khẩu mới:')"
                    vid="password"
                    rules="required|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password"
                        v-model="passwordValue"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false:null"
                        name="register-password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  label-for="register-password-confirm"
                  :label="$t('Nhập lại mật khẩu')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Nhập lại mật khẩu')"
                    rules="required|confirmed:password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="register-password-confirm"
                        v-model="passwordCon"
                        :state="errors.length > 0 ? false:null"
                        :type="passwordFieldType"
                        class="form-control-merge"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="d-flex">
                <b-button
                  variant="outline-danger"
                  block
                  style="margin-top: 0.5rem"
                  class="mr-3 ml-5"
                  @click="$router.push({name: 'account-manager'})"
                >
                  {{ $t('Hủy') }}
                </b-button>

                <b-button
                  variant="primary"
                  block
                  class="ml-3 mr-5"
                  type="submit"
                  :disabled="invalid"
                >
                  {{ $t('Thay đổi') }}
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>

      <!--      <b-card-->
      <!--        class="mt-3"-->
      <!--        :title="$t('Thay đổi mật khẩu rút tiền')"-->
      <!--      >-->
      <!--        <validation-observer-->
      <!--          ref="moneyForm"-->
      <!--          #default="{invalid}"-->
      <!--        >-->
      <!--          <b-form-->
      <!--            class="auth-register-form mt-2"-->
      <!--            @submit.prevent="changeMoneyPassword"-->
      <!--          >-->
      <!--            <b-row-->
      <!--              cols="1"-->
      <!--              class="w-50"-->
      <!--            >-->
      <!--              <b-col>-->
      <!--                &lt;!&ndash; password &ndash;&gt;-->
      <!--                <b-form-group-->
      <!--                  label-for="money-password-old"-->
      <!--                  :label="$t('Mật khẩu cũ:')"-->
      <!--                >-->
      <!--                  <validation-provider-->
      <!--                    #default="{ errors }"-->
      <!--                    :name="$t('Mật khẩu cũ:')"-->
      <!--                    rules="required"-->
      <!--                  >-->
      <!--                    <b-input-group-->
      <!--                      class="input-group-merge"-->
      <!--                      :class="errors.length > 0 ? 'is-invalid':null"-->
      <!--                    >-->
      <!--                      <b-form-input-->
      <!--                        id="money-password-old"-->
      <!--                        v-model="moneyPasswordOldValue"-->
      <!--                        class="form-control-merge"-->
      <!--                        :type="passwordFieldType"-->
      <!--                        :state="errors.length > 0 ? false:null"-->
      <!--                        name="money-password-old"-->
      <!--                      />-->
      <!--                      <b-input-group-append is-text>-->
      <!--                        <feather-icon-->
      <!--                          :icon="passwordToggleIcon"-->
      <!--                          class="cursor-pointer"-->
      <!--                          @click="togglePasswordVisibility"-->
      <!--                        />-->
      <!--                      </b-input-group-append>-->
      <!--                    </b-input-group>-->
      <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
      <!--                  </validation-provider>-->
      <!--                </b-form-group>-->
      <!--              </b-col>-->
      <!--              <b-col>-->
      <!--                &lt;!&ndash; password &ndash;&gt;-->
      <!--                <b-form-group-->
      <!--                  label-for="money-password"-->
      <!--                  :label="$t('Mật khẩu mới:')"-->
      <!--                >-->
      <!--                  <validation-provider-->
      <!--                    #default="{ errors }"-->
      <!--                    :name="$t('Mật khẩu mới:')"-->
      <!--                    vid="password"-->
      <!--                    rules="required|password"-->
      <!--                  >-->
      <!--                    <b-input-group-->
      <!--                      class="input-group-merge"-->
      <!--                      :class="errors.length > 0 ? 'is-invalid':null"-->
      <!--                    >-->
      <!--                      <b-form-input-->
      <!--                        id="money-password"-->
      <!--                        v-model="moneyPasswordValue"-->
      <!--                        class="form-control-merge"-->
      <!--                        :type="passwordFieldType"-->
      <!--                        :state="errors.length > 0 ? false:null"-->
      <!--                        name="money-password"-->
      <!--                      />-->
      <!--                      <b-input-group-append is-text>-->
      <!--                        <feather-icon-->
      <!--                          :icon="passwordToggleIcon"-->
      <!--                          class="cursor-pointer"-->
      <!--                          @click="togglePasswordVisibility"-->
      <!--                        />-->
      <!--                      </b-input-group-append>-->
      <!--                    </b-input-group>-->
      <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
      <!--                  </validation-provider>-->
      <!--                </b-form-group>-->
      <!--              </b-col>-->
      <!--              <b-col>-->
      <!--                <b-form-group-->
      <!--                  label-for="money-password-confirm"-->
      <!--                  :label="$t('Nhập lại mật khẩu')"-->
      <!--                >-->
      <!--                  <validation-provider-->
      <!--                    #default="{ errors }"-->
      <!--                    :name="$t('Nhập lại mật khẩu')"-->
      <!--                    rules="required|confirmed:password"-->
      <!--                  >-->
      <!--                    <b-input-group-->
      <!--                      class="input-group-merge"-->
      <!--                      :class="errors.length > 0 ? 'is-invalid':null"-->
      <!--                    >-->
      <!--                      <b-form-input-->
      <!--                        id="money-password-confirm"-->
      <!--                        v-model="moneyPasswordCon"-->
      <!--                        :state="errors.length > 0 ? false:null"-->
      <!--                        :type="passwordFieldType"-->
      <!--                        class="form-control-merge"-->
      <!--                      />-->
      <!--                      <b-input-group-append is-text>-->
      <!--                        <feather-icon-->
      <!--                          :icon="passwordToggleIcon"-->
      <!--                          class="cursor-pointer"-->
      <!--                          @click="togglePasswordVisibility"-->
      <!--                        />-->
      <!--                      </b-input-group-append>-->
      <!--                    </b-input-group>-->
      <!--                    <small class="text-danger">{{ errors[0] }}</small>-->
      <!--                  </validation-provider>-->
      <!--                </b-form-group>-->
      <!--              </b-col>-->
      <!--              <b-col class="d-flex">-->
      <!--                <b-button-->
      <!--                  variant="outline-danger"-->
      <!--                  block-->
      <!--                  style="margin-top: 0.5rem"-->
      <!--                  class="mr-3 ml-5"-->
      <!--                  @click="$router.push({name: 'account-manager'})"-->
      <!--                >-->
      <!--                  {{ $t('Hủy') }}-->
      <!--                </b-button>-->

      <!--                <b-button-->
      <!--                  variant="primary"-->
      <!--                  block-->
      <!--                  class="ml-3 mr-5"-->
      <!--                  type="submit"-->
      <!--                  :disabled="invalid"-->
      <!--                >-->
      <!--                  {{ $t('Thay đổi') }}-->
      <!--                </b-button>-->

      <!--              </b-col>-->
      <!--            </b-row>-->
      <!--          </b-form>-->
      <!--        </validation-observer>-->
      <!--      </b-card>-->

    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BCard, BRow, BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BCardText,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import useJwt from '@/auth/jwt/useJwt'
import {
  required, email, password, url,
} from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { formatDateTimeDb } from '@/libs/timezone'

export default {
  name: 'AccountManager',
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BCardText,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      passwordValue: '',
      passwordOldValue: '',
      passwordCon: '',
      moneyPasswordValue: '',
      moneyPasswordOldValue: '',
      moneyPasswordCon: '',
      user: {
        email: '',
        password: '',
        merchantCode: '',
        payinCallbackLink: '',
        name: '',
        createdAt: '',
        merchantKey: '',
        feeConfig: [],
      },
      url,
      required,
      email,
      password,
    }
  },
  computed: {
    feeViettelCard() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'viettel')?.percentFee
    },
    feeMobiCard() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'mobifone')?.percentFee
    },
    feeVinaCard() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'vinaphone')?.percentFee
    },
    feeVietnamobileCard() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'vietnamobile')?.percentFee
    },
    feeQR() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'payin')?.percentFee
    },
    feeMomoQr() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'momo_qr')?.percentFee
    },
    feeZaloPayQr() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'zalo_qr')?.percentFee
    },
    feeViettelQr() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'viettel_qr')?.percentFee
    },
    feeUsdt() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'usdt')?.percentFee
    },
    feePayout() {
      return this.user.feeConfig.find(item => item.payGate.gate === 'payout')?.percentFee
    },
    feePayoutUsdt() {
      return this.user.usdtOutFee
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    dateCreated() {
      return formatDateTimeDb(this.user.createdAt)
    },
  },
  mounted() {
    this.getProfileUser()
  },
  methods: {
    getProfileUser() {
      useJwt.getProfile()
        .then(response => {
          this.user = response.data.data
        })
    },
    updateProfile() {
      useJwt.updateProfile({
        email: this.user.email,
        name: this.user.name,
        payinCallbackLink: this.user.payinCallbackLink,
        createPayinOrderCallbackLink: this.user.payinCallbackLink,
        payoutCallbackLink: this.user.payinCallbackLink,
        id: this.user.id,
      })
        .then(() => {
          this.$bvToast.toast(this.$t('Cập nhật thông tin thành công'), {
            title: this.$t('Thông báo'),
            variant: 'success',
            solid: true,
          })
        })
    },
    changePassword() {
      if (this.passwordValue !== this.passwordCon) {
        this.$bvToast.toast(this.$t('Mật khẩu mới không khớp'), {
          title: this.$t('Thông báo'),
          variant: 'danger',
          solid: true,
        })
        return
      }
      if (this.passwordValue === this.passwordOldValue) {
        this.$bvToast.toast(this.$t('Mật khẩu mới không được trùng với mật khẩu cũ'), {
          title: this.$t('Thông báo'),
          variant: 'danger',
          solid: true,
        })
        return
      }
      useJwt.changePassword({
        new_password: this.passwordValue,
        password: this.passwordOldValue,
      })
        .then(() => {
          this.$bvToast.toast(this.$t('Thay đổi mật khẩu thành công'), {
            title: this.$t('Thông báo'),
            variant: 'success',
            solid: true,
          })
          this.clearPassword()
        })
        .catch(() => {
          this.$bvToast.toast(this.$t('Thay đổi mật khẩu thất bại'), {
            title: this.$t('Thông báo'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    // changeMoneyPassword() {
    //   useJwt.changePasswordWithdraw({
    //     new_password: this.moneyPasswordValue,
    //     password: this.moneyPasswordOldValue,
    //   })
    //     .then(() => {
    //       this.$bvToast.toast(this.$t('Thay đổi mật khẩu thành công'), {
    //         title: this.$t('Thông báo'),
    //         variant: 'success',
    //         solid: true,
    //       })
    //       this.clearPassword()
    //     })
    //     .catch(() => {
    //       this.$bvToast.toast(this.$t('Thay đổi mật khẩu thất bại'), {
    //         title: this.$t('Thông báo'),
    //         variant: 'danger',
    //         solid: true,
    //       })
    //     })
    // },
    clearPassword() {
      this.passwordValue = ''
      this.passwordOldValue = ''
      this.passwordCon = ''
    },
    formatDateTimeDb,
  },
}
</script>

<style scoped>

</style>
